<template>
  <div class="bg-white" style="border-bottom-left-radius: 22rem">
    <div class="relative isolate pt-0.5 lg:pt-14">
      <svg
          aria-hidden="true"
          class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]">
        <defs>
          <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" height="200" patternUnits="userSpaceOnUse" width="200"
                   x="50%"
                   y="-1">
            <path d="M100 200V.5M.5 .5H200" fill="none"/>
          </pattern>
        </defs>
        <svg class="overflow-visible fill-gray-50" x="50%" y="-1">
          <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                stroke-width="0"/>
        </svg>
        <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" height="100%" stroke-width="0" width="100%"/>
      </svg>

      <div class="mx-auto max-w-7xl px-6 lg:py-12 lg:flex lg:items-center lg:gap-x-10 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
          <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Plan & Share Across

            <span v-for="ct in coloredText" :class="ct.color">
                {{ ct.text }}
              </span>
          </h1>

          <p class="mt-6 text-lg leading-8 text-gray-600">
            Simplify your social media management. Schedule, post, and engage across multiple platforms from one
            intuitive dashboard. Save time, boost your online presence, and never miss an opportunity to connect with
            your audience.
          </p>

          <!--          <div class="mt-10 isolate flex items-center -space-x-1 overflow-hidden text-sm">
                      <img alt=""
                           class="relative z-30 inline-block h-6 w-6 rounded-full ring-2 ring-white"
                           src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80">
                      <img alt=""
                           class="relative z-20 inline-block h-6 w-6 rounded-full ring-2 ring-white"
                           src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80">
                      <img alt=""
                           class="relative z-10 inline-block h-6 w-6 rounded-full ring-2 ring-white"
                           src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80">
                      <img alt=""
                           class="relative z-0 inline-block h-6 w-6 rounded-full ring-2 ring-white"
                           src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80">

                      <p class="pl-2">
                        Join 185 other members that joined the past week.
                      </p>
                    </div>-->

          <div class="mt-10 isolate flex items-center space-x-1.5 text-sm">
            <icon v-for="platform in platforms"
                  :name="platform.icon"
                  class="h-7 w-7 rounded-full"/>
          </div>

          <p class="text-sm italic mt-2">
            Currently supporting {{ Object.keys(platforms).length }} platforms <a class="link"
                                                                                  href="https://roadmap.thelisting.app/"
                                                                                  target="_blank">with more on the
            way</a>.
          </p>

          <div class="mt-10 flex items-center gap-x-6">
            <nuxt-link class="btn-primary-md" to="/register">
              Become a member
            </nuxt-link>
          </div>
        </div>

        <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
          <img class="mx-auto w-[30.875rem] max-w-full drop-shadow-xl"
               src="https://ampire.netlify.app/images/team.svg"/>
        </div>
      </div>
    </div>
  </div>

  <section class="bg-white py-12 lg:py-24 space-y-16 border-b">
    <div class="px-6 lg:px-8">
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Fully Loaded with {{ FEATURES.length }} Features
        </h2>

        <p class="mt-6 text-lg leading-8 text-gray-600">
          Moving to The Listing App means you can say goodbye to multiple tools and hello to a single, intuitive
          platform.
        </p>
      </div>
    </div>

    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-none">
        <div class="mb-8">
          <div class="lg:hidden">
            <inputs-select id="index-features-dd"
                           :items="CATEGORIES"
                           default_option_label="Select a feature category"
                           @set="selectedFeatureCategory = $event.target.value"/>
          </div>

          <div class="hidden lg:block">
            <div class="flex space-x-4">
              <button v-for="category in CATEGORIES"
                      :class="selectedFeatureCategory === category.key ? 'shadow-indigo-600 bg-indigo-600 text-white' : 'border text-gray-500 hover:text-white hover:bg-indigo-600'"
                      class="shadow-sm rounded-md p-3 text-sm font-medium w-full"
                      @click="selectedFeatureCategory = category.key">
                {{ category.label }}
              </button>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 lg:max-w-none">
          <nuxt-link v-for="feature in filteredFeatures"
                     class="flex flex-col border rounded-2xl p-4 hover:bg-gray-100 hover:cursor-pointer">
            <div class="text-base font-semibold leading-7 text-gray-900">
              <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                <icon :name="feature.icon" class="text-white"/>
              </div>

              {{ feature.label }}
            </div>

            <p class="mt-1 leading-7 text-gray-600">
              {{ feature.description }}
            </p>
          </nuxt-link>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-white py-12 lg:py-24 space-y-16 border-b">
    <div class="px-6 lg:px-8">
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Platforms
        </h2>

        <p class="mt-6 text-lg leading-8 text-gray-600">
          Our system is designed to add new platforms seamlessly. We are constantly adding new platforms to make
          your work easier. If you have a specific platform in mind,<br><a class="underline text-blue-500"
                                                                           href="https://roadmap.thelisting.app/tabs/1-under-consideration/submit-idea"
                                                                           target="_blank">
          suggest it here</a>.
        </p>
      </div>
    </div>

    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-none">
        <div class="grid max-w-xl grid-cols-3 gap-8 lg:max-w-none lg:grid-cols-5">
          <nuxt-link v-for="platform in platforms"
                     class="flex flex-col items-center border rounded-2xl p-4 hover:bg-gray-100 hover:cursor-pointer">
            <div class="flex flex-col items-center text-base font-semibold leading-7 text-gray-900">
              <div class="mb-3 flex h-10 w-10 items-center justify-center rounded-lg border">
                <icon :name="platform.icon"/>
              </div>

              {{ platform.name }}
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-gray-900 py-12 lg:py-24 space-y-16 border-b text-white">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
        <div class="mx-auto max-w-2xl lg:max-w-none">
          <h2 class="text-xl font-bold tracking-tight">
            Integrations coming soon
          </h2>

          <p class="mt-2 text-sm">
            We are constantly working on new integrations and social media platforms. If you have a specific integration
            in mind, <a class="underline text-blue-500" href="https://roadmap.thelisting.app/tabs/1-under-consideration/submit-idea"
                        target="_blank">
            suggest it here</a>.
          </p>
        </div>

        <div
            class="mx-auto grid w-full max-w-xl grid-cols-5 items-center gap-3 lg:mx-0 lg:max-w-none lg:pl-8">
          <div v-for="wipIntegration in wipIntegrations"
               class="flex flex-col justify-start items-center text-center h-full space-y-1">
            <div class="bg-white border rounded-xl p-2 text-gray-900">
              <icon :name="wipIntegration.icon" size="26"/>
            </div>

            <span class="text-xs">
              {{ wipIntegration.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import {FEATURES} from "~/helpers/services.js"
import {infoStore} from "~/stores/info.js"

const coloredText = [
  {text: 'S', color: 'text-red-500'},
  {text: 'o', color: 'text-orange-500'},
  {text: 'c', color: 'text-yellow-500'},
  {text: 'i', color: 'text-green-500'},
  {text: 'a', color: 'text-blue-500'},
  {text: 'l', color: 'text-indigo-500'},
  {text: ' ', color: 'text-purple-500'},
  {text: 'A', color: 'text-pink-500'},
  {text: 'p', color: 'text-red-600'},
  {text: 'p', color: 'text-orange-600'},
  {text: 's', color: 'text-yellow-600'},
]

const TESTIMONIALS = [
  {
    name: 'FlySocial',
    logo: 'https://silver-clafoutis-adb019.netlify.app/logo.webp',
    description: 'We were able to increase our revenue by 30% in the first month alone. We are now able to reach a wider audience and engage with our customers more effectively.',
    website: 'https://flysocial.agency',
    email: 'hello@flysocial.agency',
  },

  {
    name: 'Cutting Edge Social',
    logo: 'https://66eeea4560bee98655626e0d--tiny-valkyrie-5b40dd.netlify.app/logo.png',
    description: 'The Listing App has helped us save time and money. It does the job of multiple tools in one, and we are able to manage our social media accounts more effectively.',
    website: 'https://cuttingedge.social',
    email: 'marketing@cuttingedge.social',
  },

  {
    name: 'Pamara',
    logo: 'https://tailwindui.com/img/logos/reform-logo-white.svg',
    description: 'Initially, we were skeptical about The Listing App, we were already using Buffer and Metricool for our clients. But the difference in UI and the ease of use made us switch to The Listing App.',
    website: 'https://pamarasocial.com',
    email: 'marketing@pamarasocial.com',
  },
]

const platforms = computed(() => infoStore().platforms)

const selectedFeatureCategory = ref('all')

const CATEGORIES = [
  {
    label: 'All',
    key: 'all',
  },
  {
    label: 'Publish, View, and Schedule',
    key: 'publish_view_schedule',
  },
  {
    label: 'Collaboration',
    key: 'collaboration',
  },
  {
    label: 'AI',
    key: 'ai',
  },
  {
    label: 'Engagement',
    key: 'engagement',
  },
  {
    label: 'Time savers',
    key: 'time_savers',
  },
  {
    label: 'Branding',
    key: 'branding',
  },
  {
    label: 'Apps and Extensions',
    key: 'apps_and_extensions',
  },
]

const filteredFeatures = computed(() => {
  if (selectedFeatureCategory.value === 'all') {
    return FEATURES
  }

  return FEATURES.filter(feature => feature.category === selectedFeatureCategory.value)
})

const wipIntegrations = computed(() => infoStore().wip_integrations)

const contactViaEmail = (email) => {
  window.open(`mailto:${email}?subject=Has The Listing App helped your business?&body=Hi, I would like to know if The Listing App has helped your business.`, '_blank')
}
</script>
